import { ReutersConfig, ReutersPartialItem, ReutersPreviewItem } from '@anews/types'

export enum ReutersActionType {
  LOAD_CONFIG_REQUEST = '@reuters/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@reuters/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@reuters/LOAD_CONFIG_FAILURE',
  UPDATE_CONFIG_REQUEST = '@reuters/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@reuters/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@reuters/UPDATE_CONFIG_FAILURE',
  LOAD_ITEM_REQUEST = '@reuters/LOAD_ITEM_REQUEST',
  LOAD_ITEM_SUCCESS = '@reuters/LOAD_ITEM_SUCCESS',
  LOAD_ITEM_FAILURE = '@reuters/LOAD_ITEM_FAILURE',
  UNLOAD_ITEM = '@reuters/UNLOAD_ITEM',
}

export type ReutersActionMap<T extends ReutersActionType> = { type: T } & ReutersAction

export type ReutersAction = ReturnType<typeof ReutersActions[keyof typeof ReutersActions]>

export const ReutersActions = {
  //
  //  loadConfig()
  //

  loadConfig: () => <const>{ type: ReutersActionType.LOAD_CONFIG_REQUEST },

  loadConfigSuccess: (config: ReutersConfig) =>
    <const>{ type: ReutersActionType.LOAD_CONFIG_SUCCESS, config },

  loadConfigFailure: (error: Error) =>
    <const>{ type: ReutersActionType.LOAD_CONFIG_FAILURE, error },

  //
  //  updateConfig()
  //

  updateConfig: (config: ReutersConfig) =>
    <const>{ type: ReutersActionType.UPDATE_CONFIG_REQUEST, config },

  updateConfigSuccess: (config: ReutersConfig) =>
    <const>{ type: ReutersActionType.UPDATE_CONFIG_SUCCESS, config },

  updateConfigFailure: (error: Error) =>
    <const>{ type: ReutersActionType.UPDATE_CONFIG_FAILURE, error },

  //
  //  loadItem()
  //

  loadItem: (item: ReutersPartialItem) =>
    <const>{ type: ReutersActionType.LOAD_ITEM_REQUEST, item },

  loadItemSuccess: (item: ReutersPreviewItem) =>
    <const>{ type: ReutersActionType.LOAD_ITEM_SUCCESS, item },

  loadItemFailure: (error: unknown) => <const>{ type: ReutersActionType.LOAD_ITEM_FAILURE, error },

  //
  //  unloadItem()
  //

  unloadItem: () => <const>{ type: ReutersActionType.UNLOAD_ITEM },
}
