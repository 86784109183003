import { del, get, post, put, query } from './client';
var endpoint = '/api/agency/lanes';
var feedLaneApi = {
    list: function () {
        return get(query(endpoint));
    },
    load: function (id, forceUpdate) {
        return get(query(endpoint + "/" + id, { forceUpdate: forceUpdate }));
    },
    filterFacebook: function (id, term, page, size) {
        if (term === void 0) { term = ''; }
        if (page === void 0) { page = 0; }
        return get(query(endpoint + "/facebook/" + id + "/filter", { term: term, page: page, size: size }));
    },
    filterRss: function (id, term, page, size) {
        if (term === void 0) { term = ''; }
        if (page === void 0) { page = 0; }
        return get(query(endpoint + "/rss/" + id + "/filter", { term: term, page: page, size: size }));
    },
    filterTweets: function (id, term, page, size) {
        if (term === void 0) { term = ''; }
        if (page === void 0) { page = 0; }
        return get(query(endpoint + "/twitter/" + id + "/filter", { term: term, page: page, size: size }));
    },
    create: function (lane) {
        return post(endpoint, lane);
    },
    update: function (lane) {
        return put("" + endpoint, lane);
    },
    remove: function (id) {
        return del(endpoint + "/" + id);
    },
};
export default feedLaneApi;
