import { del, get, post, put, query } from './client';
var endpoint = '/api/chats';
var chatsApi = {
    unread: function () {
        return get(endpoint + "/unread");
    },
    removeMessage: function (messageId) {
        return del(endpoint + "/messages/" + messageId);
    },
    historyByDate: function (date) {
        return get(query(endpoint + "/history", { date: date }));
    },
    history: function () {
        return get(query(endpoint + "/history"));
    },
    load: function (chatId) {
        return get(endpoint + "/" + chatId);
    },
    loadByMembers: function (userIds) {
        return get(query("" + endpoint, { userIds: userIds }));
    },
    loadChatsWithTitles: function () {
        return get(query(endpoint + "/gpt"));
    },
    create: function (userIds) {
        return post(query("" + endpoint, { userIds: userIds }));
    },
    createGptChat: function () {
        return post(endpoint + "/gpt");
    },
    deleteChat: function (id) {
        return del(endpoint + "/" + id + "/delete");
    },
    addMembers: function (id, userIds) {
        return put(query(endpoint + "/" + id, { userIds: userIds }));
    },
    leave: function (id) {
        return put(endpoint + "/" + id + "/leave");
    },
    sendMessage: function (chatId, content) {
        return post(endpoint + "/" + chatId + "/messages", content, {
            headers: { 'Content-Type': 'text/plain' },
        });
    },
    sendMessageGpt: function (chatId, content, role) {
        return post(endpoint + "/gpt/" + chatId + "/messages?role=" + role, content, {
            headers: { 'Content-Type': 'application/json' },
        });
    },
    sendFileMessage: function (chatId, storageFile) {
        return post(endpoint + "/" + chatId + "/fileMessages", storageFile);
    },
    previousMessages: function (id, date, size) {
        if (size === void 0) { size = 10; }
        return get(query(endpoint + "/" + id + "/messages", { date: date, size: size }));
    },
    loadMessage: function (messageId) {
        return get(endpoint + "/messages/" + messageId);
    },
    markRead: function (messageId) {
        return put(endpoint + "/messages/" + messageId + "/read");
    },
};
export default chatsApi;
