import { Reducer } from 'redux'

import { ReutersConfig, ReutersPartialItem, ReutersPreviewItem } from '@anews/types'

import { ReutersAction, ReutersActionType as ActionType } from '../actions/reuters-actions'

import { ConfigState } from './types'

export interface ReutersState {
  config: ConfigState<ReutersConfig>
  item: {
    partial: ReutersPartialItem | null
    data: ReutersPreviewItem | null
    loading: boolean
  }
}

export const initialState: ReutersState = {
  config: {
    loading: false,
    saving: false,
    data: undefined,
  },
  item: {
    partial: null,
    data: null,
    loading: false,
  },
}

function configReducer(
  config: ConfigState<ReutersConfig>,
  action: ReutersAction,
): ConfigState<ReutersConfig> {
  switch (action.type) {
    case ActionType.LOAD_CONFIG_REQUEST:
      return { ...config, loading: true }

    case ActionType.UPDATE_CONFIG_REQUEST:
      return { ...config, saving: true, data: action.config }

    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...config, loading: false, saving: false }

    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_SUCCESS:
      return { ...config, loading: false, saving: false, data: action.config }

    default:
      return config
  }
}

const reutersReducer: Reducer<ReutersState, ReutersAction> = (
  state = initialState,
  action,
): ReutersState => {
  switch (action.type) {
    case ActionType.LOAD_CONFIG_REQUEST:
    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.UPDATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...state, config: configReducer(state.config, action) }

    case ActionType.LOAD_ITEM_REQUEST:
      return { ...state, item: { partial: action.item, data: null, loading: true } }
    case ActionType.LOAD_ITEM_FAILURE:
      return { ...state, item: { ...state.item, loading: false } }
    case ActionType.LOAD_ITEM_SUCCESS:
      return { ...state, item: { partial: null, data: action.item, loading: false } }

    case ActionType.UNLOAD_ITEM:
      return { ...state, item: { partial: null, data: null, loading: false } }

    default:
      return state
  }
}

export default reutersReducer
