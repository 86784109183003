import { call, put, takeLatest } from 'redux-saga/effects'

import { feedLaneApi as api } from '@anews/api'
import { FeedLane } from '@anews/types'

import { NotificationActions } from '../actions'
import {
  FeedLaneActions,
  FeedLaneActionMap as ActionMap,
  FeedLaneActionType as ActionType,
} from '../actions/feedlane-actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const {
  listFeedLanesSuccess,
  listFeedLanesFailure,
  editFeedLaneSuccess,
  editFeedLaneFailure,
  createFeedLaneSuccess,
  createFeedLaneFailure,
  updateFeedLaneSuccess,
  updateFeedLaneFailure,
  removeFeedLaneSuccess,
  removeFeedLaneFailure,
} = FeedLaneActions

const { notifyError } = NotificationActions

/* Watchers */

function* listFeedLanesSaga(action: ActionMap<ActionType.LIST_REQUEST>): Generator {
  try {
    const lanes = yield call(api.list)
    yield put(listFeedLanesSuccess(lanes as FeedLane[]))
  } catch (error) {
    yield put(listFeedLanesFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* editFeedLaneSaga(action: ActionMap<ActionType.EDIT_REQUEST>): Generator {
  try {
    const lane = yield call(api.load, action.id, action.forceUpdate)
    yield put(editFeedLaneSuccess(lane as FeedLane))
  } catch (error) {
    yield put(editFeedLaneFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createFeedLaneSaga(action: ActionMap<ActionType.CREATE_REQUEST>): Generator {
  try {
    const lane = yield call(api.create, action.lane)
    yield put(createFeedLaneSuccess(lane as FeedLane))
  } catch (error) {
    yield put(createFeedLaneFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:createFailed'),
        error,
      }),
    )
  }
}

function* updateFeedLaneSaga(action: ActionMap<ActionType.UPDATE_REQUEST>): Generator {
  try {
    const lane = yield call(api.update, action.lane)
    yield put(updateFeedLaneSuccess(lane as FeedLane))
  } catch (error) {
    yield put(updateFeedLaneFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:updateFailed'),
        error,
      }),
    )
  }
}

function* removeFeedLaneSaga(action: ActionMap<ActionType.REMOVE_REQUEST>): Generator {
  try {
    yield call(api.remove, action.id)
    yield put(removeFeedLaneSuccess(action.id))
  } catch (error) {
    yield put(removeFeedLaneFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:deleteFailed'),
        error,
      }),
    )
  }
}

/* Root */

export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LIST_REQUEST, listFeedLanesSaga)
  },
  function* () {
    yield takeLatest(ActionType.EDIT_REQUEST, editFeedLaneSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_REQUEST, createFeedLaneSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_REQUEST, updateFeedLaneSaga)
  },
  function* () {
    yield takeLatest(ActionType.REMOVE_REQUEST, removeFeedLaneSaga)
  },
])
