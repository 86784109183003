import { get, post, put } from './client';
var endpoint = '/reuters';
var reutersApi = {
    loadConfig: function () {
        return get(endpoint + "/config");
    },
    updateConfig: function (config) {
        return put(endpoint + "/config", config);
    },
    loadSubscriptions: function () {
        return get(endpoint + "/current_user/subscriptions");
    },
    loadFilterOptions: function () {
        return get(endpoint + "/filter_options");
    },
    search: function (params) {
        return post(endpoint + "/search", params);
    },
    getItem: function (id) {
        return get(endpoint + "/items/" + encodeURIComponent(id));
    },
    getDownloadUrl: function (id, type) {
        return get(endpoint + "/items/" + encodeURIComponent(type) + "/" + encodeURIComponent(id) + "/download_url");
    },
};
export default reutersApi;
