import { call, put, select, takeLatest } from 'redux-saga/effects'

import { gptApi as api, chatsApi } from '@anews/api'

import { GptConfig, Message, ModelListResponse } from '@anews/types'

import {
  GptActions,
  GptActionMap as ActionMap,
  GptActionType as ActionType,
  NotificationActions,
} from '../actions'

import i18n from '../../i18n'

import { createRootSaga } from './helpers'

const { notifyError } = NotificationActions
const {
  createConfigSuccess,
  createConfigFailure,
  loadConfigSuccess,
  loadConfigFailure,
  updateConfigSuccess,
  updateConfigFailure,
  streamMessage,
  addMessageGptSuccess,
  addMessageGptFailure,
} = GptActions

function* loadConfigSaga(): Generator {
  try {
    const config = yield call(api.loadConfig)
    yield put(loadConfigSuccess(config as GptConfig))
  } catch (error) {
    yield put(loadConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* createConfigSaga(action: ActionMap<ActionType.CREATE_CONFIG_REQUEST>): Generator {
  try {
    const models = yield call(api.loadGptModels, action.config.bearerToken)

    if (!(models as ModelListResponse)) {
      throw new Error('Failed to load GPT models')
    }

    const config = yield call(api.createConfig, action.config)
    yield put(createConfigSuccess(config as GptConfig))
  } catch (error) {
    yield put(createConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function* updateConfigSaga(action: ActionMap<ActionType.UPDATE_CONFIG_REQUEST>): Generator {
  try {
    const config = yield call(api.updateConfig, action.config)
    yield put(updateConfigSuccess(config as GptConfig))
  } catch (error) {
    yield put(updateConfigFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:operation'),
        description: i18n.t('error:loadFailed'),
        error,
      }),
    )
  }
}

function readStream(reader: ReadableStreamDefaultReader): Promise<string> {
  return reader.read().then(({ done, value }) => {
    if (done) {
      return ''
    }
    return new TextDecoder().decode(value)
  })
}

function* addMessageGptSaga(action: ActionMap<ActionType.ADD_MESSAGE_GPT_REQUEST>): Generator {
  try {
    let response: Response
    const { apiKey, chatId, isContext, messages, model } = action
    if (action.message && !isContext && chatId) {
      const message = yield call(
        { context: chatsApi, fn: chatsApi.sendMessageGpt },
        chatId,
        action.message,
        'user',
      )
      const updateMessages = [...action.messages, message as Message]
      const openAIResponse = yield call(api.addMessageGpt, updateMessages, isContext, apiKey, model)
      response = openAIResponse as Response
    } else {
      const openAIResponse = yield call(
        api.addMessageGpt,
        messages,
        isContext,
        apiKey,
        model,
        action.message,
      )
      response = openAIResponse as Response
    }

    if (response.body) {
      let contentResponse = ''
      let messageContent = ''
      const reader = response.body.getReader()
      while (true) {
        const text = yield call(() => readStream(reader))
        if (!text) {
          break
        }
        contentResponse += text

        const partialData = contentResponse
          .split('data:')
          .filter((part: string) => part.trim() !== '' && part.trim() !== '[DONE]')

        for (let i = 0; i < partialData.length - 1; i += 1) {
          const apiResponse = JSON.parse(partialData[i])
          const { content } = apiResponse.choices[0].delta
          if (content) {
            messageContent += content
            yield put(streamMessage(content))
          }
        }

        contentResponse = partialData[partialData.length - 1]

        // Para de gerar a mensagem
        const stopGeneration = yield select(state => state.gpt.stopMessageGeneration)
        if (stopGeneration) {
          break
        }
      }
      yield put(addMessageGptSuccess())
      if (!isContext && chatId) {
        yield call(
          { context: chatsApi, fn: chatsApi.sendMessageGpt },
          chatId,
          messageContent,
          'assistant',
        )
      }
    }
  } catch (error) {
    yield put(addMessageGptFailure(error))
    yield put(
      notifyError({
        message: i18n.t('error:communication'),
        description: i18n.t('error:communicationGpt'),
        error,
      }),
    )
  }
}

/* Root */
export default createRootSaga([
  function* () {
    yield takeLatest(ActionType.LOAD_CONFIG_REQUEST, loadConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.CREATE_CONFIG_REQUEST, createConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.UPDATE_CONFIG_REQUEST, updateConfigSaga)
  },
  function* () {
    yield takeLatest(ActionType.ADD_MESSAGE_GPT_REQUEST, addMessageGptSaga)
  },
])
