export function isNotBlank(value) {
    return typeof value === 'string' && value.trim().length !== 0;
}
export function isBlank(value) {
    return !isNotBlank(value);
}
export function toBoolean(value) {
    if (typeof value === 'boolean') {
        return value;
    }
    if (isBlank(value)) {
        return false;
    }
    var lower = value.toLowerCase();
    return lower === 'true' || lower === 'yes' || lower === '1';
}
export function toSentenceCase(value) {
    if (typeof value !== 'string') {
        return undefined;
    }
    /*
     * A primeira regex coloca o primeiro caractere após um \n, ponto final, ponto de interrogação ou
     * ponto de exclamação. A segunda serve apenas colocar a primeira letra das linhas em caixa alta.
     *
     * Lógica da primeira regex:
     *   [\n.?!] -> procura por um \n, ponto final, ponto de interrogação ou ponto de exclamação
     *   [^a-zA-Z\u00C0-\u017F]* -> procura por zero ou mais caracteres que não sejam uma letras, acentuadas ou não
     *   [a-zA-Z\u00C0-\u017F] -> procura por uma letra, acentuada ou não
     *
     * Lógica da segunda regex:
     *   ^ -> dá match apenas no início da linha
     *   (\s*) -> zero ou mais caracteres de "espaço em branco"
     *   ([a-z]) -> qualquer letra
     */
    return value
        .toLowerCase()
        .replace(/([\n.?!][^a-z\u00C0-\u017F]*)([a-z\u00C0-\u017F])/g, function (match, p1, p2) { return p1 + p2.toUpperCase(); })
        .replace(/^(\s*)([a-z\u00C0-\u017F])/, function (match, p1, p2) { return p1 + p2.toUpperCase(); });
}
export function toTitleCase(value) {
    if (typeof value !== 'string') {
        return undefined;
    }
    return value.replace(/(^|\s)[a-z\u00C0-\u017F]/g, function (match) { return match.toUpperCase(); });
}
export function toggleCase(value, excludeTitleCase) {
    if (excludeTitleCase === void 0) { excludeTitleCase = false; }
    if (typeof value !== 'string') {
        return undefined;
    }
    // text > upper > sentence > title > upper > ...
    /*
     * Coloca em sentence case se cair em *qualquer uma* das condições:
     * - não tiver nenhuma letra minúscula
     * - não tiver espaço e não tiver nenhuma letra maiúscula
     */
    if (!/[a-záàâãéèêíïóôõöúçñ]/g.test(value) ||
        (!/ /g.test(value) && !/[A-ZÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]/g.test(value))) {
        return toSentenceCase(value);
    }
    /*
     * Coloca em title case *não tiver espaço* e cair em *qualquer uma* das condições:
     * - não tiver a sequência <minúscula><espaços><maíuscula>
     * - não tiver nenhuma letra maiúscula
     */
    if (!excludeTitleCase &&
        / /g.test(value) &&
        (!/[a-záàâãéèêíïóôõöúçñ] +[A-ZÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]/g.test(value) ||
            !/[A-ZÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]/g.test(value))) {
        return toTitleCase(value);
    }
    // Por fim, coloca em uppercase
    return value.toUpperCase();
}
export function equalsIgnoreCase(a, b) {
    if (typeof a === 'string' && typeof b === 'string') {
        return a.toLowerCase() === b.toLowerCase();
    }
    return a === b;
}
// Gera hash code à partir de uma string
// https://stackoverflow.com/a/7616484
export function hashCode(value) {
    /* eslint-disable no-bitwise */
    if (!value || value.length === 0) {
        return 0;
    }
    var hash = 0;
    var i;
    var c;
    for (i = 0; i < value.length; i += 1) {
        c = value.charCodeAt(i);
        hash = (hash << 5) - hash + c;
        hash |= 0; // convert to 32 bit integer
    }
    return hash;
}
export function convertToSlug(text) {
    var a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
    var b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
    var p = new RegExp(a.split('').join('|'), 'g');
    return text
        .toString()
        .toLowerCase()
        .trim()
        .replace(p, function (c) { return b.charAt(a.indexOf(c)); }) // Replace special chars
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[\s\W-]+/g, '-'); // Replace spaces, non-word characters and dashes with a single dash (-)
}
export function formatString(text) {
    return text
        .toString()
        .replace(/\u2028/g, '') // Replace HTML Left separator
        .replace(/&nbsp/g, ' '); // Replace HTML non-breaking space
}
