import { Reducer } from 'redux'

import { FeedLane, ReutersMediaType } from '@anews/types'

import { FeedLaneActionType as ActionType, FeedLaneAction } from '../actions/feedlane-actions'

export type ViewMode = 'table' | 'grid'

export type FeedLaneFilter = {
  term: string
  reutersPriority: number | null
  reutersMediaType: ReutersMediaType | null
}

export type FeedLanesListState = {
  data: FeedLane[]
  loading: boolean
}

export type FeedLanesState = {
  filter: FeedLaneFilter
  list: FeedLanesListState
  editing: FeedLane | null
  selected: FeedLane | null
  viewMode: ViewMode
  saving: boolean
  removing: boolean
}

export const initialState: FeedLanesState = {
  filter: {
    term: '',
    reutersPriority: null,
    reutersMediaType: null,
  },
  list: {
    data: [],
    loading: true,
  },
  editing: null,
  selected: null,
  viewMode: 'grid',
  saving: false,
  removing: false,
}

function listReducer(list: FeedLanesListState, action: FeedLaneAction): FeedLanesListState {
  switch (action.type) {
    case ActionType.LIST_REQUEST:
      return { ...list, loading: true }

    case ActionType.LIST_FAILURE:
      return { ...list, loading: false }

    case ActionType.LIST_SUCCESS:
      return {
        ...list,
        data: action.lanes,
        loading: false,
      }

    case ActionType.CREATE_SUCCESS:
      return { ...list, data: [...list.data, action.lane] }

    case ActionType.UPDATE_SUCCESS:
      return {
        ...list,
        data: list.data.map(lane => (lane.id === action.lane.id ? action.lane : lane)),
      }

    case ActionType.REMOVE_SUCCESS:
      return { ...list, data: list.data.filter(lane => lane.id !== action.id) }

    default:
      return list
  }
}

const lanesReducer: Reducer<FeedLanesState, FeedLaneAction> = (
  state = initialState,
  action,
): FeedLanesState => {
  switch (action.type) {
    case ActionType.UPDATE_FILTER:
      return { ...state, filter: { ...state.filter, ...action.change } }

    case ActionType.CHANGE_VIEW_MODE:
      return { ...state, viewMode: action.mode }

    case ActionType.LIST_REQUEST:
    case ActionType.LIST_FAILURE:
    case ActionType.LIST_SUCCESS:
      return { ...state, list: listReducer(state.list, action) }

    case ActionType.NEW:
    case ActionType.EDIT_SUCCESS:
      return { ...state, editing: action.lane }

    case ActionType.SELECT:
      return { ...state, selected: action.lane }

    case ActionType.CREATE_REQUEST:
    case ActionType.UPDATE_REQUEST:
      return { ...state, editing: action.lane, saving: true }

    case ActionType.REMOVE_REQUEST:
      return { ...state, removing: true }

    case ActionType.REMOVE_FAILURE:
      return { ...state, removing: false }

    case ActionType.CREATE_FAILURE:
    case ActionType.UPDATE_FAILURE:
      return { ...state, saving: false }

    case ActionType.CREATE_SUCCESS:
    case ActionType.UPDATE_SUCCESS:
      return {
        ...state,
        editing: null,
        list: listReducer(state.list, action),
        selected: state.selected?.id === action.lane.id ? action.lane : state.selected,
        removing: false,
        saving: false,
      }
    case ActionType.REMOVE_SUCCESS:
      return {
        ...state,
        editing: null,
        list: listReducer(state.list, action),
        selected: state.selected?.id === action.id ? null : state.selected,
        removing: false,
        saving: false,
      }

    default:
      return state
  }
}

export default lanesReducer
