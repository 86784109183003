import { Post } from '@anews/types'

import { displayDate } from '@anews/utils'

import i18n from '../../../i18n'
import { removeMarkTags } from '../../../utils/xml-utils'
import { copyToClipboard } from '../../../utils/clipboard-utils'

export function postToText(post: Post) {
  const { slug, excerpt, date, content } = post
  const postText: string[] = []

  if (slug) {
    postText.push(`${i18n.t('words:slug')} : ${slug}\n`)
  }

  postText.push(`${i18n.t('words:date')} : ${displayDate(date)}`)

  if (excerpt) {
    postText.push(`${i18n.t('post:excerpt')} : ${excerpt}`)
  }

  if (content) {
    postText.push(`${i18n.t('words:content')} : ${content.replace(/<\/?[^>]+(>|$)/g, '')}`)
  }

  return removeMarkTags(postText.join('\n'))
}

export default function postToClipboard(post: Post) {
  const text = postToText(post)

  return copyToClipboard(text, i18n.t('phrases:copiedToClipboard'), i18n.t('error:actionFailed'))
}
