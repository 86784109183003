import React, { FC, useCallback, useEffect, useRef } from 'react'

/*
 * O componente original, react-infinite-scroll-component, não estava disparando o `loadMore`, e
 * o projeto estava abandonado.
 *
 * O código abaixo foi uma solução apontada em uma issue do projeto:
 * https://github.com/ankeetmaini/react-infinite-scroll-component/issues/380#issuecomment-1646970802
 */

interface InfiniteScrollProps {
  load: () => void
  hasMore: boolean
  loader: React.ReactNode
  children?: React.ReactNode
  endMessage?: React.ReactNode
}

const InfiniteScroll: FC<InfiniteScrollProps> = ({
  load,
  hasMore,
  loader,
  children,
  endMessage,
}) => {
  const sentinelRef = useRef<HTMLDivElement>(null)
  const observerRef = useRef<IntersectionObserver | null>(null)

  const handleIntersect = useCallback(
    (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      // Check if the sentinel element is intersecting, and if so, call the load function
      if (entries[0].isIntersecting && hasMore) {
        load()
      }
    },
    [load, hasMore],
  )

  useEffect(() => {
    // Create a new IntersectionObserver when the component mounts
    observerRef.current = new IntersectionObserver(handleIntersect, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    })

    // Attach the observer to the sentinel element
    if (sentinelRef.current) {
      observerRef.current.observe(sentinelRef.current)
    }

    // Clean up the observer when the component unmounts
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [load, handleIntersect])

  useEffect(() => {
    // When the hasMore prop changes, disconnect the previous observer and reattach it to the new sentinel element
    if (observerRef.current && sentinelRef.current) {
      observerRef.current.disconnect()
      observerRef.current.observe(sentinelRef.current)
    }
  }, [hasMore])

  return (
    <div>
      {children}
      <div ref={sentinelRef}>{hasMore && loader}</div>
      {!hasMore && endMessage}
    </div>
  )
}

export default InfiniteScroll
