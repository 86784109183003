import { call, put, select, takeLatest } from 'redux-saga/effects'

import { chatsApi as api, gptApi, userApi } from '@anews/api'
import { Chat, Message, User } from '@anews/types'

import { ChatsActionType, ChatsActionMap, ChatsActions } from '../actions/chats-actions'

import { createRootSaga } from './helpers'

const {
  createChatSuccess,
  createChatFailure,
  createChatGptSuccess,
  createChatGptFailure,
  createTitleSuccess,
  createTitleFailure,
  markAsReadSuccess,
  markAsReadFailure,
  unreadChatsSuccess,
  unreadChatsFailure,
  loadChatSuccess,
  loadChatFailure,
  leaveChatSuccess,
  leaveChatFailure,
  leaveChat,
  addMembersFailure,
  createChat,
  openChat,
  closeChat,
  minimizeChat,
  addMembers,
  minimizeAll,
} = ChatsActions

const delay = (ms: number) => new Promise(res => setTimeout(res, ms))

/* Watchers */

function* closeChatSaga(action: ChatsActionMap<ChatsActionType.CLOSE_CHAT>): Generator {
  try {
    const openChats = yield select(state => state.chats.openChats)
    if (action.chat.members.length > 2) {
      yield put(leaveChat(action.chat.id))
    }
    if ((openChats as number[]).length > 0) {
      // minimiza e aguarda um pouco para abrir os popovers novamente
      // solução encontrada para atualizar a posição das janelas de chat
      // que ficavam soltas ao remover um chat
      yield put(minimizeAll())
      yield call(delay, 30)
      yield put(openChat((openChats as number[]).filter(id => id !== action.chat.id)))
    }
  } catch (error) {
    yield put(leaveChatFailure(error))
  }
}

function* createChatSaga(action: ChatsActionMap<ChatsActionType.CREATE_REQUEST>): Generator {
  try {
    const chat = yield call(api.create, action.userIds)

    const openChats = yield select(state => state.chats.openChats)
    if ((openChats as number[]).length > 0) {
      yield put(minimizeAll())
      yield call(delay, 30)
      yield put(openChat([...(openChats as number[]), (chat as Chat).id]))
    } else {
      yield put(openChat([(chat as Chat).id]))
    }
    yield put(createChatSuccess(chat as Chat))
  } catch (error) {
    yield put(createChatFailure(error))
  }
}

function* createChatGptSaga(): Generator {
  try {
    const chat = yield call(api.createGptChat)

    const openChats = yield select(state => state.chats.openChats)
    const chats = yield select(state => state.chats.chats.data)

    // Verifica se tem outros chat gpt abertos e fecha
    if ((chats as number[]).length > 0) {
      const chatToClose = (chats as Chat[]).find(c => c.id !== (chat as Chat).id && c.gpt)

      if (chatToClose) {
        yield put(closeChat(chatToClose as Chat))
      }
    }

    if ((openChats as number[]).length > 0) {
      yield put(minimizeAll())
      yield call(delay, 30)
      yield put(openChat([...(openChats as number[]), (chat as Chat).id]))
    } else {
      yield put(openChat([(chat as Chat).id]))
    }

    yield put(createChatGptSuccess(chat as Chat))
  } catch (error) {
    yield put(createChatGptFailure(error))
  }
}

function* openChatSaga(action: ChatsActionMap<ChatsActionType.OPEN_CHAT_GPTCHAT>): Generator {
  try {
    const chat = yield call(api.load, action.chatId)

    const openChats = yield select(state => state.chats.chats.data)

    // Verifica se tem outros chat gpt abertos e fecha
    if ((openChats as number[]).length > 0) {
      const chatToClose = (openChats as Chat[]).find(c => c.id !== (chat as Chat).id && c.gpt)

      if (chatToClose) {
        yield put(closeChat(chatToClose as Chat))
      }
    }

    yield put(openChat([(chat as Chat).id]))
    yield put(loadChatSuccess(chat as Chat))
  } catch (error) {
    yield put(loadChatFailure(error))
  }
}

function* createTitleSaga(action: ChatsActionMap<ChatsActionType.CREATE_TITLE_REQUEST>): Generator {
  try {
    const { apiKey, model, chatId, message } = action
    const chat = yield call(gptApi.createTitle, chatId, message, apiKey, model)
    yield put(createTitleSuccess(chat as Chat))
  } catch (error) {
    yield put(createTitleFailure(error))
  }
}

function* loadChatSaga(action: ChatsActionMap<ChatsActionType.LOAD_REQUEST>): Generator {
  try {
    const chat = yield call(api.load, action.id)

    const openChats = yield select(state => state.chats.openChats)
    if ((openChats as number[]).length > 0) {
      yield put(minimizeAll())
      yield call(delay, 30)
      yield put(openChat([...(openChats as number[])]))
    }

    yield put(loadChatSuccess(chat as Chat))
  } catch (error) {
    yield put(loadChatFailure(error))
  }
}

function* markAsReadSaga(action: ChatsActionMap<ChatsActionType.MARK_READ_REQUEST>): Generator {
  try {
    for (let index = 0; index < action.messageIds.length; index += 1) {
      const messageId = action.messageIds[index]
      const message = yield call(api.markRead, messageId)
      yield put(markAsReadSuccess(message as Message))
    }
  } catch (error) {
    yield put(markAsReadFailure(error))
  }
}

function* unreadChatsSaga(): Generator {
  try {
    const chats = yield call(api.unread)
    yield put(unreadChatsSuccess(chats as Chat[]))
  } catch (error) {
    yield put(unreadChatsFailure(error))
  }
}

function* leaveChatSaga(action: ChatsActionMap<ChatsActionType.LEAVE_REQUEST>): Generator {
  try {
    const chat = yield call(api.leave, action.id)
    yield put(leaveChatSuccess(chat as Chat))
  } catch (error) {
    yield put(leaveChatFailure(error))
  }
}

function* addMembersSaga(action: ChatsActionMap<ChatsActionType.ADD_MEMBERS_REQUEST>): Generator {
  try {
    if (action.currentMembersIds.length > 2) {
      yield call(api.addMembers, action.id, action.userIds)
    } else {
      yield put(createChat([...action.currentMembersIds, ...action.userIds]))
      yield put(minimizeChat(action.id))
    }
  } catch (error) {
    yield put(addMembersFailure(error))
  }
}

function* addGroupSaga(action: ChatsActionMap<ChatsActionType.ADD_GROUP>): Generator {
  try {
    const users = yield call(userApi.activeUsers, action.groupId)
    const userIds = (users as User[]).map(user => user.id)
    yield put(addMembers(action.id, userIds, action.currentMembersIds))
  } catch (error) {
    yield put(addMembersFailure(error))
  }
}

/* Root */

export default createRootSaga([
  // ... outras sagas
  function* () {
    yield takeLatest(ChatsActionType.CREATE_REQUEST, createChatSaga)
  },
  function* () {
    yield takeLatest(ChatsActionType.CREATE_GPT_CHAT_REQUEST, createChatGptSaga)
  },
  function* () {
    yield takeLatest(ChatsActionType.OPEN_CHAT_GPTCHAT, openChatSaga)
  },
  function* () {
    yield takeLatest(ChatsActionType.CREATE_TITLE_REQUEST, createTitleSaga)
  },
  function* () {
    yield takeLatest(ChatsActionType.LOAD_REQUEST, loadChatSaga)
  },
  function* () {
    yield takeLatest(ChatsActionType.MARK_READ_REQUEST, markAsReadSaga)
  },
  function* () {
    yield takeLatest(ChatsActionType.UNREAD_REQUEST, unreadChatsSaga)
  },
  function* () {
    yield takeLatest(ChatsActionType.CLOSE_CHAT, closeChatSaga)
  },
  function* () {
    yield takeLatest(ChatsActionType.LEAVE_REQUEST, leaveChatSaga)
  },
  function* () {
    yield takeLatest(ChatsActionType.ADD_MEMBERS_REQUEST, addMembersSaga)
  },
  function* () {
    yield takeLatest(ChatsActionType.ADD_GROUP, addGroupSaga)
  },
])
