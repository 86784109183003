import { useCallback, useEffect, useRef } from 'react';
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export default function useInterval(callback, delay) {
    var savedCallback = useRef();
    // Lembra do último callback
    useEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    var id = useRef(null);
    var clear = useCallback(function () {
        if (id.current) {
            clearInterval(id.current);
            id.current = null;
        }
    }, []);
    var reset = useCallback(function () {
        clear();
        if (delay !== null && delay > 0) {
            id.current = window.setInterval(function () {
                if (savedCallback.current) {
                    savedCallback.current();
                }
            }, delay);
        }
    }, [delay, clear]);
    useEffect(function () {
        reset();
        return clear;
    }, [delay, reset, clear]);
    return reset;
}
