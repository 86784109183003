import { Reducer } from 'redux'

import { GptConfig, Message } from '@anews/types'

import { GptAction, GptActionType as ActionType } from '../actions'

import { ConfigState } from './types'

export interface GptState {
  config: ConfigState<GptConfig>
  loading: boolean
  chatMessages: Message[]
  streamMessage: string
  message: Message
  stopMessageGeneration: boolean
}

export const initialState: GptState = {
  config: {
    loading: false,
    saving: false,
    data: undefined,
  },
  streamMessage: '',
  chatMessages: [],
  loading: false,
  stopMessageGeneration: false,
  // Quando ele esta gerando a msg tenho que colocar a mesma que vai esta na msg
  message: {
    id: 1,
    uuid: '1',
    sender: {
      nickname: 'chatG',
      name: 'GPT',
      id: 0,
      uuid: 'a',
      passwordReset: false,
      readRate: 0,
      email: 'chatGpt@snews.com',
      online: false,
      excluded: false,
      authenticated2FA: false,
    },
    chatId: 1,
    timestamp: new Date().toISOString(),
    destinations: [],
    content: '',
    role: 'assistant',
  },
}

function configReducer(config: ConfigState<GptConfig>, action: GptAction): ConfigState<GptConfig> {
  switch (action.type) {
    case ActionType.LOAD_CONFIG_REQUEST:
      return { ...config, loading: true }

    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.CREATE_CONFIG_REQUEST:
      return { ...config, saving: true, data: action.config }

    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...config, loading: false, saving: false }

    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_SUCCESS:
      return { ...config, loading: false, saving: false, data: action.config }

    default:
      return config
  }
}

const gptReducer: Reducer<GptState, GptAction> = (state = initialState, action): GptState => {
  switch (action.type) {
    // Config
    case ActionType.LOAD_CONFIG_REQUEST:
    case ActionType.LOAD_CONFIG_SUCCESS:
    case ActionType.LOAD_CONFIG_FAILURE:
    case ActionType.CREATE_CONFIG_REQUEST:
    case ActionType.CREATE_CONFIG_SUCCESS:
    case ActionType.CREATE_CONFIG_FAILURE:
    case ActionType.UPDATE_CONFIG_REQUEST:
    case ActionType.UPDATE_CONFIG_SUCCESS:
    case ActionType.UPDATE_CONFIG_FAILURE:
      return { ...state, config: configReducer(state.config, action) }

    case ActionType.CHAT_MESSAGES:
      return {
        ...state,
        chatMessages: [action.newMessage, ...action.messages],
      }
    case ActionType.LOAD_CHAT_MESSAGES:
      return { ...state, chatMessages: action.messages }

    case ActionType.STREAM_MESSAGE: {
      // Verifica se a mensagem já existe no array
      const messageExists = state.chatMessages.some(msg => msg.id === 1)

      // Se a mensagem não existir, adicione-a no início do array
      const updatedChatMessages = messageExists
        ? state.chatMessages
        : [initialState.message, ...state.chatMessages]

      // Em seguida, atualize a mensagem específica (com id igual a 1)
      const finalChatMessages = updatedChatMessages.map(msg =>
        msg.id === 1 ? { ...msg, content: state.streamMessage + action.message } : msg,
      )
      return {
        ...state,
        streamMessage: state.streamMessage + action.message,
        chatMessages: finalChatMessages,
      }
    }

    case ActionType.STOP_MESSAGE_GPT:
      return { ...state, stopMessageGeneration: true }
    case ActionType.ADD_MESSAGE_GPT_REQUEST:
      return { ...state, loading: true, streamMessage: initialState.streamMessage }
    case ActionType.ADD_MESSAGE_GPT_FAILURE:
    case ActionType.ADD_MESSAGE_GPT_SUCCESS:
      return {
        ...state,
        loading: false,
        stopMessageGeneration: false,
        chatMessages: state.chatMessages.filter(msg => msg.id !== 1),
      }
    default:
      return state
  }
}

export default gptReducer
