import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Modal from 'antd/es/modal/Modal'
import message from 'antd/es/message'
import Input from 'antd/es/input'

import { chatsApi } from '@anews/api'
import { Guideline, News, Post, Reportage, Story } from '@anews/types'

import { useOnce } from '@anews/hooks'

import { ChatsActions, GptActions } from '../../redux/actions'
import { useTranslation } from '../../i18n'
import { PropsMapper, RootState } from '../../redux/reducers'
import { guidelineToText } from '../main/guidelines/guidelineToClipboard'
import { reportageToText } from '../main/reportages/reportageToClipboard'
import { storyToText } from '../main/stories/StoryViewer/storyToClipboard'
import { newsToText } from '../main/news/newsToClipboard'
import { postToText } from '../main/posts/postToClipboard'

interface OwnProps {
  visible: boolean
  guideline?: Guideline
  news?: News
  post?: Post
  reportage?: Reportage
  story?: Story
  onClose: () => void
  closeDrawer?: () => void
}

type Props = PropsMapper<typeof mapStateToProps, typeof dispatchProps, OwnProps>

const SendToChatGptModal = ({
  visible,
  guideline,
  news,
  post,
  reportage,
  story,
  openChatGpt,
  onClose,
  closeDrawer,
  gptConfig,
  loadGptConfig,
  addMessageGpt,
  createChatTitle,
}: Props) => {
  useOnce(loadGptConfig)

  const { t } = useTranslation()

  const [content, setContent] = useState('')

  const onSend = async () => {
    try {
      if (content.trim() === '') {
        message.warn(t('validation:required'))
        return
      }

      let documentContent = ''

      if (post) documentContent = `${content}\n${postToText(post).trim()}`

      if (news) documentContent = `${content}\n${newsToText(news).trim()}`

      if (story) documentContent += `${content}\n${storyToText(story).trim()}`

      if (reportage) documentContent += `${content}\n${reportageToText(reportage).trim()}`

      if (guideline) documentContent += `${content}\n${guidelineToText(guideline).trim()}`

      const chat = await chatsApi.createGptChat()
      openChatGpt(chat.id)
      addMessageGpt(
        chat.id,
        false,
        [],
        documentContent,
        'assistant',
        gptConfig?.bearerToken,
        gptConfig?.model,
      )

      setContent('')
      onClose()

      if (closeDrawer) closeDrawer()
      createChatTitle(chat.id, documentContent, gptConfig?.bearerToken)
    } catch (error) {
      console.error(error)
      message.error(t('error:actionFailed'))
    }
  }

  // Na visualização da lauda o evento da tecla espeço não é capturado, por conta do atalho do espelho
  // para tratar a captura do espaço no modal
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        event.preventDefault() // Prevenir o comportamento padrão (scroll)
        setContent(prev => `${prev} `)
      }
    }

    if (visible) {
      window.addEventListener('keydown', handleKeyDown)

      // Qando o modal é fechado
      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [visible])

  return (
    <Modal
      title={t('chat:assistant')}
      visible={visible}
      okText={t('words:send')}
      cancelText={t('words:cancel')}
      width={350}
      maskClosable
      closable={false}
      onOk={onSend}
      onCancel={onClose}
      destroyOnClose
    >
      <div style={{ display: 'block' }}>{t('chat:assistantHelp')}</div>
      <Input.TextArea
        autoFocus
        placeholder={t('chat:sendDocumentContext')}
        autoSize={{ minRows: 3, maxRows: 10 }}
        onChange={event => setContent(event.target.value)}
        value={content}
        required
      />
    </Modal>
  )
}

const mapStateToProps = (state: RootState) => ({
  gptConfig: state.gpt.config.data,
})

const dispatchProps = {
  openChatGpt: ChatsActions.openChatGpt,
  createChatTitle: ChatsActions.createTitle,
  addMessageGpt: GptActions.addMessageGpt,
  loadGptConfig: GptActions.loadConfig,
}

export default connect(mapStateToProps, dispatchProps)(memo(SendToChatGptModal))
