import { BaseEditor, BaseSelection, Transforms } from 'slate'
import { HistoryEditor } from 'slate-history'
import { ReactEditor } from 'slate-react'

import { toggleCase } from '@anews/utils'
import { RichTextBlock } from '@anews/types'

export function toggleRichCase(editor: BaseEditor & ReactEditor & HistoryEditor): void {
  const mappings: { at: [number, number]; global: [number, number] }[] = []

  let fullText = ''
  let selectionBegin = -1
  let selectionEnd = -1

  const children = editor.children as RichTextBlock[]
  const currentSelection = editor.selection
  const { begin, end } = extractBeginEnd(editor.selection) || {}

  children.forEach((child, block) => {
    // Se criar um type que não seja 'paragraph', deve não funcionar
    if (child.type === 'paragraph' && Array.isArray(child.children)) {
      child.children.forEach((leaf, index) => {
        const prev = fullText.length
        fullText += leaf.text

        if (begin && end) {
          if (begin.path[0] === block && begin.path[1] === index) {
            selectionBegin = prev + begin.offset
          }
          if (end.path[0] === block && end.path[1] === index) {
            selectionEnd = prev + end.offset
          }
        }

        mappings.push({ at: [block, index], global: [prev, fullText.length] })
      })
    }
  })

  if (selectionBegin !== -1 && selectionEnd !== -1) {
    fullText =
      fullText.substring(0, selectionBegin) +
      toggleCase(fullText.substring(selectionBegin, selectionEnd)) +
      fullText.substring(selectionEnd)
  } else {
    fullText = toggleCase(fullText, true)
  }

  mappings.forEach(m =>
    Transforms.insertText(editor, fullText.substring(m.global[0], m.global[1]), { at: m.at }),
  )

  if (currentSelection) {
    ReactEditor.focus(editor)
    Transforms.select(editor, currentSelection)
  }
}

function extractBeginEnd(selection: BaseSelection) {
  if (!selection) return null

  const { anchor, focus } = selection

  // Index 0 de path é o RichTextBlock
  // Index 1 de path é o Leaf
  // Offset é a posição do cursor no texto

  if (anchor.path[0] < focus.path[0]) return { begin: anchor, end: focus }
  if (anchor.path[0] > focus.path[0]) return { begin: focus, end: anchor }
  if (anchor.path[1] < focus.path[1]) return { begin: anchor, end: focus }
  if (anchor.path[1] > focus.path[1]) return { begin: focus, end: anchor }
  if (anchor.offset < focus.offset) return { begin: anchor, end: focus }
  if (anchor.offset > focus.offset) return { begin: focus, end: anchor }

  return null
}
