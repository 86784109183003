var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { del, get, post, put, query } from './client';
var endpoint = '/api/users';
var userApi = {
    all: function () {
        return get(endpoint);
    },
    activeUsers: function (groupId) {
        return get(query(endpoint + "/active", { groupId: groupId }));
    },
    editors: function () {
        return get(endpoint + "/editors");
    },
    imageEditors: function () {
        return get(endpoint + "/image_editors");
    },
    producers: function () {
        return get(endpoint + "/producers");
    },
    reporters: function () {
        return get(endpoint + "/reporters");
    },
    pageFilter: function (filter, flags, page, size) {
        if (filter === void 0) { filter = ''; }
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query(endpoint + "/filter", __assign(__assign({ filter: filter }, flags), { page: page, size: size })));
    },
    load: function (id) {
        return get(endpoint + "/" + id);
    },
    create: function (user) {
        return post(endpoint, user);
    },
    updateMe: function (user) {
        return put(endpoint + "/me", user);
    },
    update: function (user) {
        return put("" + endpoint, user);
    },
    remove: function (ids) {
        return del(query(endpoint, { ids: ids }));
    },
    restore: function (id) {
        return put(endpoint + "/" + id + "/restore");
    },
    registerLastNotice: function () {
        return post(endpoint + "/register_last_notice");
    },
    resetPassword: function (id) {
        return post(endpoint + "/" + id + "/reset_password");
    },
};
export default userApi;
