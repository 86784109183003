import { Reducer } from 'redux'

import { Chat } from '@anews/types'

import { ChatsActionType, ChatsAction } from '../actions/chats-actions'

export interface ChatListState {
  data: Chat[]
  loading: boolean
}

export interface ChatState {
  chats: ChatListState
  openChats: number[]
}

export const initialState: ChatState = {
  chats: {
    data: [],
    loading: false,
  },
  openChats: [],
}

/**
 * Substitui um chat existente ou adiciona um chat no final
 * @param chats Lista de chats atual
 * @param receivedChat chat recebido
 */
const upsertChat = (chats: Chat[], receivedChat: Chat) => {
  if (chats.findIndex(c => c.id === receivedChat.id) > -1) {
    return chats.map(c => {
      if (c.id === receivedChat.id) {
        return receivedChat
      }
      return c
    })
  }
  return [receivedChat, ...chats]
}

/**
 * merge de dois arrays excluindo repetidos
 * @param chats
 * @param chatsToMerge
 */
const mergeChats = (chats: Chat[], chatsToMerge: Chat[]) =>
  chats.concat(chatsToMerge.filter(chat => chats.findIndex(c => c.id === chat.id) < 0))

const chatListReducer = (chats: ChatListState, action: ChatsAction) => {
  switch (action.type) {
    case ChatsActionType.CLOSE_CHAT:
      return { ...chats, data: chats.data.filter(c => c.id !== action.chat.id) }

    case ChatsActionType.CREATE_REQUEST:
    case ChatsActionType.OPEN_CHAT_GPTCHAT:
    case ChatsActionType.CREATE_GPT_CHAT_REQUEST:
    case ChatsActionType.CREATE_TITLE_REQUEST:
    case ChatsActionType.LOAD_REQUEST:
    case ChatsActionType.LOAD_BY_MEMBERS_REQUEST:
    case ChatsActionType.UNREAD_REQUEST:
    case ChatsActionType.LEAVE_REQUEST:
    case ChatsActionType.ADD_MEMBERS_REQUEST:
      return { ...chats, loading: true }

    case ChatsActionType.CREATE_FAILURE:
    case ChatsActionType.CREATE_GPT_CHAT_FAILURE:
    case ChatsActionType.CREATE_TITLE_FAILURE:
    case ChatsActionType.LOAD_FAILURE:
    case ChatsActionType.LOAD_BY_MEMBERS_FAILURE:
    case ChatsActionType.UNREAD_FAILURE:
    case ChatsActionType.LEAVE_FAILURE:
    case ChatsActionType.ADD_MEMBERS_FAILURE:
      return { ...chats, loading: false }

    case ChatsActionType.CREATE_SUCCESS:
    case ChatsActionType.CREATE_GPT_CHAT_SUCCESS:
    case ChatsActionType.CREATE_TITLE_SUCCESS:
    case ChatsActionType.LOAD_SUCCESS:
    case ChatsActionType.LOAD_BY_MEMBERS_SUCCESS:
    case ChatsActionType.ADD_MEMBERS_SUCCESS:
    case ChatsActionType.MEMBER_LEFT:
      return { ...chats, data: upsertChat(chats.data, action.chat), loading: false }

    case ChatsActionType.UNREAD_SUCCESS:
      return { ...chats, data: mergeChats(chats.data, action.chats), loading: false }

    // saiu do chat, não adiciona na lista de chats ativos
    case ChatsActionType.LEAVE_SUCCESS:
      return { ...chats, loading: false }

    default:
      return chats
  }
}

const chatsReducers: Reducer<ChatState, ChatsAction> = (state = initialState, action) => {
  switch (action.type) {
    case ChatsActionType.OPEN_CHAT:
      return {
        ...state,
        openChats: [...action.chatId, ...state.openChats],
      }
    case ChatsActionType.MINIMIZE_CHAT:
      return {
        ...state,
        openChats: state.openChats.filter(chatId => chatId !== action.chatId),
      }
    case ChatsActionType.MINIMIZE_ALL:
      return {
        ...state,
        openChats: [],
      }
    case ChatsActionType.CLOSE_CHAT:
      return {
        ...state,
        chats: chatListReducer(state.chats, action),
        openChats: state.openChats.filter(chatId => chatId !== action.chat.id),
      }
    case ChatsActionType.CREATE_REQUEST:
    case ChatsActionType.CREATE_FAILURE:
    case ChatsActionType.CREATE_SUCCESS:
    case ChatsActionType.CREATE_GPT_CHAT_REQUEST:
    case ChatsActionType.CREATE_GPT_CHAT_FAILURE:
    case ChatsActionType.CREATE_GPT_CHAT_SUCCESS:
    case ChatsActionType.CREATE_TITLE_REQUEST:
    case ChatsActionType.CREATE_TITLE_FAILURE:
    case ChatsActionType.CREATE_TITLE_SUCCESS:
    case ChatsActionType.OPEN_CHAT_GPTCHAT:
    case ChatsActionType.LOAD_REQUEST:
    case ChatsActionType.LOAD_FAILURE:
    case ChatsActionType.LOAD_SUCCESS:
    case ChatsActionType.LOAD_BY_MEMBERS_REQUEST:
    case ChatsActionType.LOAD_BY_MEMBERS_FAILURE:
    case ChatsActionType.LOAD_BY_MEMBERS_SUCCESS:
    case ChatsActionType.UNREAD_REQUEST:
    case ChatsActionType.UNREAD_SUCCESS:
    case ChatsActionType.UNREAD_FAILURE:
    case ChatsActionType.ADD_MEMBERS_REQUEST:
    case ChatsActionType.ADD_MEMBERS_SUCCESS:
    case ChatsActionType.ADD_MEMBERS_FAILURE:
    case ChatsActionType.MEMBER_LEFT:
      return { ...state, chats: chatListReducer(state.chats, action) }
    case ChatsActionType.CLEAR:
      return initialState
    default:
      return state
  }
}
export default chatsReducers
