import { Reducer } from 'redux'

import { License } from '@anews/types'

import { LicenseAction, LicenseActionType as ActionType } from '../actions'

export type LicenseState = typeof initialState

export const initialState = {
  loadingInfo: false as boolean,
  loadingSetUrl: false as boolean,
  loadingRegister: false as boolean,
  data: undefined as License | undefined,
}

//
//  license reducer
//

function licenseDataReducer(state: LicenseState, action: LicenseAction): LicenseState {
  switch (action.type) {
    //
    //  INFO LICENSE...
    //
    case ActionType.INFO_LICENSE_REQUEST:
      return { ...state, loadingInfo: true }
    case ActionType.INFO_LICENSE_FAILURE:
      return { ...state, loadingInfo: false }
    case ActionType.INFO_LICENSE_SUCCESS:
      return { ...state, data: action.license, loadingInfo: false }
    //
    //  SET SERVER_URL...
    //
    case ActionType.SET_SERVER_URL_REQUEST:
      return { ...state, loadingSetUrl: true }
    case ActionType.SET_SERVER_URL_FAILURE:
      return { ...state, loadingSetUrl: false }
    case ActionType.SET_SERVER_URL_SUCCESS:
      return { ...state, data: action.license, loadingSetUrl: false }
    //
    //  REGISTER...
    //
    case ActionType.REGISTER_REQUEST:
      return { ...state, loadingRegister: true }
    case ActionType.REGISTER_FAILURE:
      return { ...state, loadingRegister: false }
    case ActionType.REGISTER_SUCCESS:
      return { ...state, data: action.license, loadingRegister: false }
    //
    //  DEFAULT
    //
    default:
      return state
  }
}

//
//  main reducer
//

const licenseReducer: Reducer<LicenseState, LicenseAction> = (state = initialState, action) => {
  if (Object.values(ActionType).includes(action.type)) {
    return licenseDataReducer(state, action)
  }
  return state
}

export default licenseReducer
