import { FeedLane, ReutersMediaType } from '@anews/types'

import { ViewMode } from '../reducers/feedlanes-reducer'

export enum FeedLaneActionType {
  NEW = '@feedlane/NEW',
  SELECT = '@feedlane/SELECT',
  UPDATE_FILTER = '@feedlane/UPDATE_FILTER',
  CHANGE_VIEW_MODE = '@feedlane/CHANGE_VIEW_MODE',
  LIST_REQUEST = '@feedlane/LIST_REQUEST',
  LIST_SUCCESS = '@feedlane/LIST_SUCCESS',
  LIST_FAILURE = '@feedlane/LIST_FAILURE',
  EDIT_REQUEST = '@feedlane/EDIT_REQUEST',
  EDIT_SUCCESS = '@feedlane/EDIT_SUCCESS',
  EDIT_FAILURE = '@feedlane/EDIT_FAILURE',
  CREATE_REQUEST = '@feedlane/CREATE_REQUEST',
  CREATE_SUCCESS = '@feedlane/CREATE_SUCCESS',
  CREATE_FAILURE = '@feedlane/CREATE_FAILURE',
  UPDATE_REQUEST = '@feedlane/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@feedlane/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@feedlane/UPDATE_FAILURE',
  REMOVE_REQUEST = '@feedlane/REMOVE_REQUEST',
  REMOVE_SUCCESS = '@feedlane/REMOVE_SUCCESS',
  REMOVE_FAILURE = '@feedlane/REMOVE_FAILURE',
}

export type FeedLaneActionMap<T extends FeedLaneActionType> = { type: T } & FeedLaneAction

export type FeedLaneAction = ReturnType<typeof FeedLaneActions[keyof typeof FeedLaneActions]>

export const FeedLaneActions = {
  //
  //	newFeedLane()
  //

  newFeedLane: (lane: FeedLane | null) => <const>{ type: FeedLaneActionType.NEW, lane },

  //
  //	selectFeedLane()
  //

  selectFeedLane: (lane: FeedLane | null) => <const>{ type: FeedLaneActionType.SELECT, lane },

  //
  //  updateFilter()
  //

  updateFilter: (
    change: Partial<{
      term: string
      reutersPriority: number | null
      reutersMediaType: ReutersMediaType
    }>,
  ) => <const>{ type: FeedLaneActionType.UPDATE_FILTER, change },

  //
  //  changeViewMode()
  //

  changeViewMode: (mode: ViewMode) => <const>{ type: FeedLaneActionType.CHANGE_VIEW_MODE, mode },

  //
  //	listFeedLanes()
  //

  listFeedLanes: () => <const>{ type: FeedLaneActionType.LIST_REQUEST },

  listFeedLanesSuccess: (lanes: FeedLane[]) =>
    <const>{ type: FeedLaneActionType.LIST_SUCCESS, lanes },

  listFeedLanesFailure: (error: Error) => <const>{ type: FeedLaneActionType.LIST_FAILURE, error },

  //
  //	editFeedLane()
  //

  editFeedLane: (id: number, forceUpdate?: boolean) =>
    <const>{ type: FeedLaneActionType.EDIT_REQUEST, id, forceUpdate },

  editFeedLaneSuccess: (lane: FeedLane) => <const>{ type: FeedLaneActionType.EDIT_SUCCESS, lane },

  editFeedLaneFailure: (error: Error) => <const>{ type: FeedLaneActionType.EDIT_FAILURE, error },

  //
  //	createFeedLane()
  //

  createFeedLane: (lane: FeedLane) => <const>{ type: FeedLaneActionType.CREATE_REQUEST, lane },

  createFeedLaneSuccess: (lane: FeedLane) =>
    <const>{ type: FeedLaneActionType.CREATE_SUCCESS, lane },

  createFeedLaneFailure: (error: Error) =>
    <const>{ type: FeedLaneActionType.CREATE_FAILURE, error },

  //
  //	updateFeedLane()
  //

  updateFeedLane: (lane: FeedLane) => <const>{ type: FeedLaneActionType.UPDATE_REQUEST, lane },

  updateFeedLaneSuccess: (lane: FeedLane) =>
    <const>{ type: FeedLaneActionType.UPDATE_SUCCESS, lane },

  updateFeedLaneFailure: (error: Error) =>
    <const>{ type: FeedLaneActionType.UPDATE_FAILURE, error },

  //
  //	removeFeedLane()
  //

  removeFeedLane: (id: number) => <const>{ type: FeedLaneActionType.REMOVE_REQUEST, id },

  removeFeedLaneSuccess: (id: number) => <const>{ type: FeedLaneActionType.REMOVE_SUCCESS, id },

  removeFeedLaneFailure: (error: Error) =>
    <const>{ type: FeedLaneActionType.REMOVE_FAILURE, error },
}
