import { License } from '@anews/types'

export enum LicenseActionType {
  // check()
  INFO_LICENSE_REQUEST = '@license/INFO_LICENSE_REQUEST',
  INFO_LICENSE_SUCCESS = '@license/INFO_LICENSE_SUCCESS',
  INFO_LICENSE_FAILURE = '@license/INFO_LICENSE_FAILURE',
  // daysToExpire()
  DAYS_LICENSE_REQUEST = '@license/DAYS_LICENSE_REQUEST',
  // SetServerUrl()
  SET_SERVER_URL_REQUEST = '@license/SET_SERVER_URL_REQUEST',
  SET_SERVER_URL_SUCCESS = '@license/SET_SERVER_URL_SUCCESS',
  SET_SERVER_URL_FAILURE = '@license/SET_SERVER_URL_FAILURE',
  // register()
  REGISTER_REQUEST = '@license/REGISTER_REQUEST',
  REGISTER_SUCCESS = '@license/REGISTER_SUCCESS',
  REGISTER_FAILURE = '@license/REGISTER_FAILURE',
}

export type LicenseActionMap<T extends LicenseActionType> = { type: T } & LicenseAction
export type LicenseAction = ReturnType<typeof LicenseActions[keyof typeof LicenseActions]>

//
//  licenseInfo()
//

export const LicenseActions = {
  createLicenseInfo: () => <const>{ type: LicenseActionType.INFO_LICENSE_REQUEST },

  createLicenseInfoSuccess: (license: License) =>
    <const>{ type: LicenseActionType.INFO_LICENSE_SUCCESS, license },

  createLicenseInfoFailure: (error: unknown) =>
    <const>{ type: LicenseActionType.INFO_LICENSE_FAILURE, error },

  //
  // daysToExpire()
  //

  notifyDaysToExpire: () => <const>{ type: LicenseActionType.DAYS_LICENSE_REQUEST },

  //
  //  setServerUrl()
  //

  createSetServerUrl: (url: string) =>
    <const>{ type: LicenseActionType.SET_SERVER_URL_REQUEST, url },

  createSetServerUrlSuccess: (license: License) =>
    <const>{ type: LicenseActionType.SET_SERVER_URL_SUCCESS, license },

  createSetServerUrlFailure: (error: unknown) =>
    <const>{ type: LicenseActionType.SET_SERVER_URL_FAILURE, error },

  //
  //  register()
  //

  createLicenseRegister: (license: License) =>
    <const>{ type: LicenseActionType.REGISTER_REQUEST, license },

  createLicenseRegisterSuccess: (license: License) =>
    <const>{ type: LicenseActionType.REGISTER_SUCCESS, license },

  createLicenseRegisterFailure: (error: unknown) =>
    <const>{ type: LicenseActionType.REGISTER_FAILURE, error },
}
