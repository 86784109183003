import { AgendaContact, Contact, News, TranslationDictionary } from '@anews/types'

import { displayDate, isNotBlank, richToPlain } from '@anews/utils'

import i18n from '../../../i18n'
import { removeMarkTags } from '../../../utils/xml-utils'
import { copyToClipboard } from '../../../utils/clipboard-utils'

function getContactText(contact: Contact) {
  const { name, profession, numbers, emails, address, info } = contact as AgendaContact
  const contactText: string[] = []

  contactText.push(`${i18n.t('words:contact')}: ${name}`)

  if (profession) {
    contactText.push(`${i18n.t('contact:profession')}: ${profession}`)
  }

  if (numbers && numbers.length > 0) {
    contactText.push(`${i18n.t('contact:numbers')}: `)

    numbers.forEach(number => {
      contactText.push(
        `${number.value}(${i18n.t(
          `contact:numberType_${number.label.toLowerCase()}` as TranslationDictionary,
        )})`,
      )
    })
  }

  if (emails && emails.length > 0) {
    contactText.push(`${i18n.t('contact:emails')}: `)

    emails.forEach(email => {
      contactText.push(`${email}`)
    })
  }

  if (address) {
    contactText.push(`${i18n.t('words:address')}: ${address}`)
  }

  if (isNotBlank(info)) {
    contactText.push(`${i18n.t('words:info')}: ${info}`)
  }

  return contactText.join('\n')
}

export function newsToText(news: News) {
  const newsText: string[] = []
  const { slug, date, lead, source, address, content, contacts } = news

  if (slug) {
    newsText.push(`${i18n.t('words:slug')}: ${slug}`)
  }

  newsText.push(`${i18n.t('words:date')} : ${displayDate(date)}`)

  if (source) {
    newsText.push(`${i18n.t('news:source')}: ${source}`)
  }

  if (lead) {
    newsText.push(`${i18n.t('news:lead')}: ${lead}`)
  }

  if (address) {
    newsText.push(`${i18n.t('words:address')}: ${address}`)
  }

  if (isNotBlank(richToPlain(content))) {
    newsText.push(`${i18n.t('words:info')}: ${richToPlain(content)}`)
  }

  if (contacts) {
    contacts.forEach(contact => {
      newsText.push(getContactText(contact))
      newsText.push('')
    })
  }

  return removeMarkTags(newsText.join('\n'))
}

export default function newsToClipboard(news: News) {
  const text = newsToText(news)

  return copyToClipboard(text, i18n.t('phrases:copiedToClipboard'), i18n.t('error:actionFailed'))
}
