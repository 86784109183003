import React, { memo, useState, useEffect, useCallback, useMemo } from 'react'

import Button from 'antd/es/button'
import Divider from 'antd/es/divider'
import Col from 'antd/es/col'
import PageHeader from 'antd/es/page-header'
import Row from 'antd/es/row'
import Tabs from 'antd/es/tabs'

import { CheckCircleOutlined, CopyOutlined, DiffOutlined } from '@ant-design/icons'

import Tooltip from 'antd/es/tooltip'

import message from 'antd/es/message'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCommentLines } from '@fortawesome/pro-regular-svg-icons'

import {
  Team,
  DocumentType,
  Guideline,
  ChecklistTask,
  GuidelineRevision,
  StorageFile,
  CustomMetadata,
  GptConfig,
} from '@anews/types'
import {
  mapJoin,
  displayDate,
  displayTime,
  isBlank,
  isNotBlank,
  highlight,
  richToPlain,
} from '@anews/utils'

import { guidelineApi, storageFileApi, teamApi } from '@anews/api'

import { CarouselModal, ColumnProps, CompactTable, ViewerItem } from '../../global'
import { SelectorMap } from '../../../redux/selectors/helpers'

import ContactCompactViewer from '../contacts/ContactCompactViewer'
import { useTranslation } from '../../../i18n'

import RevisionCard from '../revision/RevisionCard'

import RichTextViewer from '../../global/RichText/RichTextViewer'

import { MetadataViewer } from '../../global/CustomMetadata'

import SendToChatGptModal from '../../global/SendToChatGptModal'

import guidelineToClipboard from './guidelineToClipboard'
import GuidelineFiles from './GuidelineFiles'

interface Props {
  guideline?: Guideline
  editable: boolean
  customMetadata: CustomMetadata[]
  gptConfig?: GptConfig
  classifications: SelectorMap<string>
  companies: SelectorMap<string>
  editorials: SelectorMap<string>
  programs: SelectorMap<string>
  types: SelectorMap<Partial<DocumentType>>
  users: SelectorMap<string>
  vehicles: SelectorMap<string>
  onEdit?: (id: number) => void
  onClose?: () => void
}

function GuidelineViewer({
  guideline,
  editable,
  customMetadata,
  gptConfig,
  classifications,
  companies,
  editorials,
  programs,
  types,
  users,
  vehicles,
  onClose,
  onEdit,
}: Props) {
  const { t } = useTranslation()

  const [teamObj, setTeamObj] = useState<Team>()
  const [storageFiles, setStorageFiles] = useState<StorageFile[]>([])
  const [sendGptModalVisible, setSendGptModalVisible] = useState(false)

  // A listagem das equipes é por data, mas a equipe que está na pauta pode não
  // estar na lista de equipes da data selecionada no componente pai desse viewer,
  // então carrega a equipe aqui mesmo pelo ID dela.
  useEffect(() => {
    async function loadTeam(id?: number) {
      try {
        setTeamObj(id ? await teamApi.load(id) : undefined)
      } catch (error) {
        console.error(error)
      }
    }

    async function loadStorageFiles(files?: StorageFile[]) {
      try {
        if (files) {
          const filesIds = files?.map(f => f.id)
          setStorageFiles(files ? await storageFileApi.loadFiles(filesIds) : [])
        }
      } catch (error) {
        console.error(error)
      }
    }

    loadTeam(guideline?.teamId)
    loadStorageFiles(guideline?.storageFiles)
  }, [guideline?.storageFiles, guideline?.teamId])

  const getText = useCallback(
    e => {
      if (e.detail === 1 && guideline) {
        guidelineToClipboard(guideline, {
          programs,
          users,
          companies,
          editorials,
          vehicles,
          classifications,
        })
      }
    },
    [guideline, programs, users, companies, editorials, vehicles, classifications],
  )

  //
  // Versões
  //

  const [versionsVisible, setVersionsVisible] = useState(false)
  const [guidelineVersions, setGuidelineVersions] = useState<GuidelineRevision[]>([])

  const versionsCards = useMemo(
    () =>
      guidelineVersions.map(version => (
        <RevisionCard
          date={version.revisionDate}
          ip={version.revisorIP}
          nickname={version.revisorNickname}
          slug={version.slug}
          content={version}
        />
      )),
    [guidelineVersions],
  )

  const versionsHandler = async () => {
    if (guideline) {
      try {
        const result = await guidelineApi.loadChangesHistory(guideline.id)
        setGuidelineVersions(result)
        setVersionsVisible(true)
      } catch (error) {
        console.error(error)
        message.error(t('error:loadFailed'))
      }
    }
  }

  if (!guideline) {
    return null
  }

  const actions = [
    <Tooltip key="copy" placement="bottom" title={t('phrases:copyToClipboard')}>
      <Button onClick={getText} icon={<CopyOutlined />} />
    </Tooltip>,
  ]

  actions.push(
    <Tooltip key="version" placement="bottom" title={t('words:versions')}>
      <Button onClick={versionsHandler} icon={<DiffOutlined />} />
    </Tooltip>,
  )

  if (gptConfig && gptConfig.bearerToken) {
    actions.push(
      <Tooltip key="sendChatGpt" placement="bottom" title={t('chat:assistant')}>
        <Button
          icon={<FontAwesomeIcon icon={faCommentLines} />}
          onClick={() => setSendGptModalVisible(true)}
        />
      </Tooltip>,
    )
  }

  editable &&
    actions.push(
      <Button key={1} onClick={() => onEdit && onEdit(guideline.id)}>
        {t('words:edit')}
      </Button>,
    )

  onClose &&
    actions.push(
      <Button key={2} onClick={onClose} type="primary">
        {t('words:close')}
      </Button>,
    )

  const date = displayDate(guideline.date)
  const classification = classifications[guideline.classificationId!]
  const company = companies[guideline.branchId!]
  const type = types[guideline.typeId!] ? types[guideline.typeId!].abbreviation : undefined

  let team = ''

  if (teamObj) {
    team = isBlank(teamObj.membersNicknames)
      ? teamObj.name!
      : `${teamObj.name} (${teamObj.membersNicknames})`
  }

  const editorsNames = mapJoin(users, guideline.editorsIds)
  const producersNames = mapJoin(users, guideline.producersIds)
  const reportersNames = mapJoin(users, guideline.reportersIds)

  const editorialsNames = mapJoin(editorials, guideline.editorialsIds)
  const programsNames = mapJoin(programs, guideline.programsIds) || t('program:drawer')
  const vehiclesNames = mapJoin(vehicles, guideline.vehiclesIds)

  //
  //  Conteúdo
  //

  const contentChildren = []

  /* Proposta */
  if (isNotBlank(richToPlain(guideline.proposal))) {
    contentChildren.push(
      <ViewerItem key="proposal" label={t('guideline:proposal')}>
        <RichTextViewer content={guideline.proposal} />
      </ViewerItem>,
    )
  }

  /* Encaminhamento */
  if (isNotBlank(richToPlain(guideline.referral))) {
    contentChildren.push(
      <ViewerItem key="referral" label={t('guideline:referral')}>
        <RichTextViewer content={guideline.referral} />
      </ViewerItem>,
    )
  }

  /* Informações */
  if (isNotBlank(richToPlain(guideline.information))) {
    contentChildren.push(
      <ViewerItem key="info" label={t('words:info')}>
        <RichTextViewer content={guideline.information} />
      </ViewerItem>,
    )
  }

  //
  //  Roteiros
  //

  const guidesChildren = (guideline?.guides || []).map(guide => (
    <div key={guide.id}>
      {/* Date e hora */}
      <Row>
        <Col span={12}>
          <ViewerItem label={t('words:date')}>{displayDate(guide.scheduleDate)}</ViewerItem>
        </Col>
        {guide.scheduleTime && (
          <Col span={12}>
            <ViewerItem label="Hora">{displayTime(guide.scheduleTime)}</ViewerItem>
          </Col>
        )}
      </Row>

      {/* Entrevistado */}
      <ViewerItem label={t('guideline:interviewee')}>{highlight(guide.interviewee)}</ViewerItem>

      {/* Endereço */}
      <ViewerItem label={t('words:address')}>{highlight(guide.address)}</ViewerItem>

      {/* Observações */}
      {isNotBlank(richToPlain(guide.observation)) && <RichTextViewer content={guide.observation} />}

      {guide.contacts!.map(contact => (
        <div key={contact.id}>
          <Divider dashed />
          <ContactCompactViewer contact={contact} />
        </div>
      ))}
    </div>
  ))

  //
  // Tarefas
  //

  const { production } = guideline
  const columns: ColumnProps<ChecklistTask>[] = [
    { key: 'date', dataIndex: 'date', title: t('words:date'), render: text => displayDate(text) },
    { key: 'time', dataIndex: 'time', title: t('words:time'), render: text => displayTime(text) },
    {
      key: 'label',
      dataIndex: 'label',
      title: t('checklist:task'),
      render: text => highlight(text),
    },
    {
      key: 'done',
      dataIndex: 'done',
      title: t('checklist:done'),
      align: 'center',
      render: done => (done ? <CheckCircleOutlined /> : ''),
    },
  ]
  const tasksChildren = production ? (
    <CompactTable
      rowKey="id"
      dataSource={production.tasks}
      columns={columns}
      expandable={{
        expandedRowRender: ({ information }) => <RichTextViewer content={information} />,
        rowExpandable: record => !!record.information,
      }}
      pagination={false}
    />
  ) : undefined

  const extraContent =
    guidesChildren.length !== 0 ||
    !!tasksChildren ||
    (guideline.storageFiles && guideline.storageFiles.length !== 0)

  //
  //  Render
  //

  return (
    <PageHeader
      title={
        <Tooltip
          title={highlight(isBlank(type) ? guideline.slug : `${type} ${guideline.slug}`)}
          placement="left"
        >
          {highlight(isBlank(type) ? guideline.slug : `${type} ${guideline.slug}`)}
        </Tooltip>
      }
      extra={actions}
    >
      {/* Data e Praça */}
      <Row>
        <Col span={12}>
          <ViewerItem label={t('words:date')}>{date}</ViewerItem>
        </Col>
        {company && (
          <Col span={12}>
            <ViewerItem label={t('words:branch')}>{company}</ViewerItem>
          </Col>
        )}
      </Row>

      {/* Programas */}
      <ViewerItem label={t('words:programs')}>{programsNames}</ViewerItem>

      {/* Pauteiros */}
      <ViewerItem label={t('guideline:producers')}>{producersNames}</ViewerItem>

      {/* Repórteres */}
      <ViewerItem label={t('words:reporters')}>{reportersNames}</ViewerItem>

      {/* Editores */}
      <ViewerItem label={t('words:editors')}>{editorsNames}</ViewerItem>

      {/* Equipe */}
      <ViewerItem label={t('words:team')}>{team}</ViewerItem>

      {/* Editorias */}
      <ViewerItem label={t('words:editorials')}>{editorialsNames}</ViewerItem>

      {/* Veículos */}
      <ViewerItem label={t('words:vehicles')}>{vehiclesNames}</ViewerItem>

      {/* Classificação e Imagens */}
      <Row>
        {classification && (
          <Col span={12}>
            <ViewerItem label={t('words:classification')}>{classification}</ViewerItem>
          </Col>
        )}
        {isNotBlank(guideline.cameraman) && (
          <Col span={12}>
            <ViewerItem label={t('words:cameraman')}>{guideline.cameraman}</ViewerItem>
          </Col>
        )}
      </Row>

      <MetadataViewer metadata={customMetadata} values={guideline.metadata} />

      {!extraContent ? (
        <>{contentChildren}</>
      ) : (
        <Tabs defaultActiveKey="1" style={{ paddingTop: 8 }}>
          <Tabs.TabPane tab={t('guideline:content')} key="1">
            {contentChildren}
          </Tabs.TabPane>
          {guidesChildren.length ? (
            <Tabs.TabPane tab={t('guideline:guides')} key="2">
              {guidesChildren}
            </Tabs.TabPane>
          ) : (
            <></>
          )}

          {tasksChildren ? (
            <Tabs.TabPane tab={t('checklist:tasks')} key="3">
              {tasksChildren}
            </Tabs.TabPane>
          ) : (
            <></>
          )}

          {guideline.storageFiles && guideline.storageFiles.length ? (
            <Tabs.TabPane tab={t('words:attachments')} key="4">
              <GuidelineFiles files={storageFiles} editable={false} />
            </Tabs.TabPane>
          ) : (
            <></>
          )}
        </Tabs>
      )}

      {/* Versões */}
      <CarouselModal
        content={versionsCards}
        visible={versionsVisible}
        title={t('words:versions')}
        onClose={() => setVersionsVisible(false)}
      />

      {/* Assistente GPT */}
      {sendGptModalVisible && (
        <SendToChatGptModal
          visible={sendGptModalVisible}
          guideline={guideline}
          closeDrawer={() => onClose && onClose()}
          onClose={() => setSendGptModalVisible(false)}
        />
      )}
    </PageHeader>
  )
}

export default memo(GuidelineViewer)
