export * from './block';
export * from './calendar';
export * from './chat';
export * from './checklists';
export * from './contacts';
export * from './entity-lock';
export * from './guidelines';
export * from './institution';
export * from './media';
export * from './news';
export * from './notice';
export * from './post';
export * from './report';
export * from './reportages';
export * from './round';
export * from './rundown';
export * from './rundown_template';
export * from './segment';
export * from './session';
export * from './story';
export * from './team';
export * from './user';
export var SubscriptionType;
(function (SubscriptionType) {
    SubscriptionType["CHAT"] = "/topic/chat";
    SubscriptionType["CHECKLISTS"] = "/topic/productions";
    SubscriptionType["CONTACTS"] = "/topic/contacts";
    SubscriptionType["BLOCKS"] = "/topic/blocks";
    SubscriptionType["BLOCK_TEMPLATES"] = "/topic/block_templates";
    SubscriptionType["CALENDAR"] = "/topic/calendar";
    SubscriptionType["CALENDAR_EVENT"] = "/topic/calendar_event";
    SubscriptionType["CUSTOM_METADATA"] = "/topic/custom_metadata";
    SubscriptionType["ENTITY_LOCKER"] = "/topic/entity_locker";
    SubscriptionType["GUIDELINES"] = "/topic/assignments";
    SubscriptionType["INDEX"] = "/topic/index";
    SubscriptionType["INSTITUTION"] = "/topic/institutions";
    SubscriptionType["LDAP"] = "/topic/ldap";
    SubscriptionType["MEDIA"] = "/topic/media";
    SubscriptionType["NEWS"] = "/topic/proposals";
    SubscriptionType["NOTICES"] = "/topic/notices";
    SubscriptionType["NOTIFICATIONS"] = "/topic/notifications";
    SubscriptionType["POSTS"] = "/topic/posts";
    SubscriptionType["REPORTAGES"] = "/topic/news_reports";
    SubscriptionType["REPORTS"] = "/topic/reports";
    SubscriptionType["ROUNDS"] = "/topic/rounds";
    SubscriptionType["RUNDOWNS"] = "/topic/rundowns";
    SubscriptionType["RUNDOWN_TEMPLATES"] = "/topic/rundown_templates";
    SubscriptionType["SEGMENT"] = "/topic/segments";
    SubscriptionType["SESSIONS"] = "/topic/sessions";
    SubscriptionType["STORIES"] = "/topic/stories";
    SubscriptionType["STORY_TEMPLATES"] = "/topic/story_templates";
    SubscriptionType["TEAMS"] = "/topic/teams";
    SubscriptionType["USERS"] = "/topic/users";
})(SubscriptionType || (SubscriptionType = {}));
export var ServerEventType;
(function (ServerEventType) {
    ServerEventType["CREATE"] = "CREATE";
    ServerEventType["DELETE"] = "DELETE";
    ServerEventType["UPDATE"] = "UPDATE";
    ServerEventType["PATCH"] = "PATCH";
    ServerEventType["MOVE"] = "MOVE";
    ServerEventType["ORDER"] = "ORDER";
    ServerEventType["LOCK"] = "LOCK";
    ServerEventType["UNLOCK"] = "UNLOCK";
    ServerEventType["TAKE_OVER"] = "TAKE_OVER";
    ServerEventType["READ"] = "READ";
    ServerEventType["MEMBERS_ADDED"] = "MEMBERS_ADDED";
    ServerEventType["MEMBER_LEFT"] = "MEMBER_LEFT";
    ServerEventType["MESSAGE_READ"] = "MESSAGE_READ";
    ServerEventType["MESSAGE_SENT"] = "MESSAGE_SENT";
    ServerEventType["MESSAGE_SENT_GPT"] = "MESSAGE_SENT_GPT";
    ServerEventType["LOGGED_IN"] = "LOGGED_IN";
    ServerEventType["LOGGED_OUT"] = "LOGGED_OUT";
    ServerEventType["IS_TYPING"] = "IS_TYPING";
    ServerEventType["REMINDER"] = "REMINDER";
})(ServerEventType || (ServerEventType = {}));
