var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { del, get, patch, post, put, query } from './client';
var endpoint = '/api/stories';
var storyApi = {
    filter: function (params, page, size) {
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query(endpoint + "/filter", __assign(__assign({}, params), { page: page, size: size })));
    },
    update: function (story) {
        return put(endpoint, story);
    },
    updateField: function (id, field, value) {
        var _a;
        // Enviar undefined não funciona para limpar o valor
        return patch(endpoint + "/" + id, (_a = {}, _a[field] = value === undefined ? null : value, _a));
    },
    toggleMultipleStatus: function (ids, status, updatePrevious) {
        return post(query(endpoint + "/display_status", { ids: ids, status: status, updatePrevious: updatePrevious }));
    },
    listExcluded: function (filter, page, size) {
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 25; }
        return get(query(endpoint + "/excluded", __assign(__assign({}, filter), { page: page, size: size })));
    },
    counter: function (filter) {
        return post(endpoint + "/counter", filter);
    },
    remove: function (ids) {
        return del(query("" + endpoint, { ids: ids }));
    },
    createFromText: function (_a) {
        var text = _a.text, params = __rest(_a, ["text"]);
        return post(query(endpoint + "/text", params), { text: text });
    },
    createFromGuidelines: function (ids, date, programId) {
        return post(query(endpoint + "/assignment", { ids: ids, date: date, programId: programId }));
    },
    createFromNews: function (ids, dest) {
        var _a = typeof dest === 'object' ? dest : { date: undefined, programId: undefined }, date = _a.date, programId = _a.programId;
        var blockId = typeof dest === 'number' ? dest : undefined;
        return post(query(endpoint + "/news", { ids: ids, date: date, programId: programId, blockId: blockId }));
    },
    createFromReportages: function (ids, date, programId) {
        return post(query(endpoint + "/reportage", { ids: ids, date: date, programId: programId }));
    },
    restore: function (ids, date, programId) {
        return put(query(endpoint + "/restore", { ids: ids, date: date, programId: programId }));
    },
    load: function (id, lock, takeOver) {
        if (takeOver === void 0) { takeOver = false; }
        return get(query(endpoint + "/" + id, { lock: lock, takeOver: takeOver }));
    },
    loadChangesHistory: function (id) {
        return get(endpoint + "/" + id + "/revisions");
    },
    loadRevision: function (id, revisionNumber, showDiff) {
        return get(query(endpoint + "/" + id + "/revisions/" + revisionNumber, { showDiff: showDiff }));
    },
    listMedia: function (id) {
        return get(endpoint + "/" + id + "/media");
    },
    listCg: function (id) {
        return get(endpoint + "/" + id + "/cg");
    },
    removeMedia: function (id, subId) {
        return del(endpoint + "/" + id + "/media/" + subId);
    },
    addMedia: function (id, mamId, media) {
        return post(query(endpoint + "/" + id + "/media", { mamId: mamId }), media);
    },
    createPlaceholder: function (_a) {
        var storyId = _a.storyId, media = _a.media, rest = __rest(_a, ["storyId", "media"]);
        return post(query(endpoint + "/" + storyId + "/placeholder", rest), media);
    },
    changeIgnoreDuration: function (storyId, subSectionId) {
        return post(endpoint + "/" + storyId + "/media/" + subSectionId + "/ignore");
    },
    syncMedia: function (storyId, subSectionId, updateDb) {
        return put(query(endpoint + "/" + storyId + "/media/" + subSectionId + "/sync", { updateDb: updateDb }));
    },
    //
    //  config
    //
    loadConfig: function () {
        return get(endpoint + "/config");
    },
    createConfig: function (config) {
        return post(endpoint + "/config", config);
    },
    updateConfig: function (config) {
        return put(endpoint + "/config", config);
    },
};
export default storyApi;
