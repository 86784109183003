var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var BLANK_CHILD_TEXT = {
    text: '',
    color: 'inherit',
    bold: false,
    italic: false,
    underline: false,
};
export var EMPTY_RICH_TEXT = [
    {
        type: 'paragraph',
        children: [
            __assign({}, BLANK_CHILD_TEXT),
        ],
    },
];
export var newRichText = function (text) {
    if (text === void 0) { text = ''; }
    return [
        {
            type: 'paragraph',
            children: [
                __assign(__assign({}, BLANK_CHILD_TEXT), { text: text }),
            ],
        },
    ];
};
