import { GptConfig, Message } from '@anews/types'

export enum GptActionType {
  LOAD_CONFIG_REQUEST = '@gpt/LOAD_CONFIG_REQUEST',
  LOAD_CONFIG_SUCCESS = '@gpt/LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_FAILURE = '@gpt/LOAD_CONFIG_FAILURE',
  CREATE_CONFIG_REQUEST = '@gpt/CREATE_CONFIG_REQUEST',
  CREATE_CONFIG_SUCCESS = '@gpt/CREATE_CONFIG_SUCCESS',
  CREATE_CONFIG_FAILURE = '@gpt/CREATE_CONFIG_FAILURE',
  UPDATE_CONFIG_REQUEST = '@gpt/UPDATE_CONFIG_REQUEST',
  UPDATE_CONFIG_SUCCESS = '@gpt/UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAILURE = '@gpt/UPDATE_CONFIG_FAILURE',
  ADD_MESSAGE_GPT_REQUEST = '@gpt/ADD_MESSAGE_GPT_REQUEST',
  ADD_MESSAGE_GPT_SUCCESS = '@gpt/ADD_MESSAGE_GPT_SUCCESS',
  ADD_MESSAGE_GPT_FAILURE = '@gpt/ADD_MESSAGE_GPT_FAILURE',
  STREAM_MESSAGE = '@gpt/STREAM_MESSAGE',
  CHAT_MESSAGES = '@gpt/CHAT_MESSAGES',
  LOAD_CHAT_MESSAGES = '@gpt/LOAD_CHAT_MESSAGES',
  STOP_MESSAGE_GPT = '@gpt/STOP_MESSAGE_GPT',
}

export type GptActionMap<T extends GptActionType> = { type: T } & GptAction

export type GptAction = ReturnType<typeof GptActions[keyof typeof GptActions]>

export const GptActions = {
  //
  //  loadConfig()
  //

  loadConfig: () => <const>{ type: GptActionType.LOAD_CONFIG_REQUEST },
  loadConfigSuccess: (config: GptConfig) =>
    <const>{ type: GptActionType.LOAD_CONFIG_SUCCESS, config },
  loadConfigFailure: (error: Error) => <const>{ type: GptActionType.LOAD_CONFIG_FAILURE, error },

  //
  // createConfig()
  //

  createConfig: (config: GptConfig) => <const>{ type: GptActionType.CREATE_CONFIG_REQUEST, config },
  createConfigSuccess: (config: GptConfig) =>
    <const>{ type: GptActionType.CREATE_CONFIG_SUCCESS, config },
  createConfigFailure: (error: Error) =>
    <const>{ type: GptActionType.CREATE_CONFIG_FAILURE, error },

  //
  // updateConfig()
  //
  updateConfig: (config: GptConfig) => <const>{ type: GptActionType.UPDATE_CONFIG_REQUEST, config },
  updateConfigSuccess: (config: GptConfig) =>
    <const>{ type: GptActionType.UPDATE_CONFIG_SUCCESS, config },
  updateConfigFailure: (error: Error) =>
    <const>{ type: GptActionType.UPDATE_CONFIG_FAILURE, error },

  // streamMessage()
  streamMessage: (message: string) => <const>{ type: GptActionType.STREAM_MESSAGE, message },

  // chatMessages()
  chatMessages: (messages: Message[], newMessage: Message) =>
    <const>{ type: GptActionType.CHAT_MESSAGES, messages, newMessage },

  // loadChatMessages()
  loadChatMessages: (messages: Message[]) =>
    <const>{ type: GptActionType.LOAD_CHAT_MESSAGES, messages },

  // stopMessageGpt()
  stopMessageGpt: () => <const>{ type: GptActionType.STOP_MESSAGE_GPT },

  //
  // addMessageGpt()
  //
  addMessageGpt: (
    chatId: number | undefined,
    isContext: boolean,
    messages: Message[],
    message: string | undefined,
    role: string,
    apiKey: string | undefined,
    model: string | undefined,
  ) =>
    <const>{
      type: GptActionType.ADD_MESSAGE_GPT_REQUEST,
      chatId,
      isContext,
      messages,
      message,
      role,
      apiKey,
      model,
    },
  addMessageGptSuccess: () => <const>{ type: GptActionType.ADD_MESSAGE_GPT_SUCCESS },
  addMessageGptFailure: (error: Error) =>
    <const>{ type: GptActionType.ADD_MESSAGE_GPT_FAILURE, error },
}
