import { combineReducers } from 'redux'

import adminLogs from './adminlogs-reducer'
import auth from './auth-reducer'
import authLogs from './authlogs-reducer'
import branch from './branch-reducer'
import chats from './chats-reducer'
import checklistResources from './checklistresources-reducer'
import checklistTypes from './checklisttypes-reducer'
import checklists from './checklists-reducer'
import classifications from './classifications-reducer'
import companies from './companies-reducer'
import contactGroups from './contactgroups-reducer'
import contacts from './contacts-reducer'
import customMetadata from './custom-metadata-reducer'
import devices from './devices-reducer'
import documents from './documents-reducer'
import editorials from './editorials-reducer'
import facebook from './facebook-reducer'
import facebookCategories from './facebookcategories-reducer'
import facebookPages from './facebookpage-reducer'
import feedLanes from './feedlanes-reducer'
import google from './google-reducer'
import groups from './groups-reducer'
import gpt from './gpt-reducer'
import guidelines from './guidelines-reducer'
import iitemplates from './iitemplates-reducer'
import index from './index-reducer'
import institutions from './institutions-reducer'
import ldap from './ldap-reducer'
import license from './license-reducer'
import locks from './entitylock-reducer'
import mail from './mail-reducer'
import medias from './medias-reducer'
import mosplugin from './mosplugin-reducer'
import news from './news-reducer'
import nominatim from './nominatim-reducer'
import posts from './posts-reducer'
import programs from './programs-reducer'
import reports from './reports-reducer'
import reportCategories from './reportcategories-reducer'
import reportEvents from './reportevents-reducer'
import reportages from './reportages-reducer'
import reuters from './reuters-reducer'
import rounds from './rounds-reducer'
import rssCategories from './rsscategories-reducer'
import rssFeeds from './rssfeeds-reducer'
import rundownTemplates from './rundowntemplates-reducer'
import rundowns from './rundowns-reducer'
import segments from './segments-reducer'
import souv from './souv-reducer'
import stories from './stories-reducer'
import storageFile from './storageFile.reducer'
import streaming from './streaming-reducer'
import twitter from './twitter-reducer'
import twitterCategories from './twittercategories-reducer'
import twitterLists from './twitterlists-reducer'
import twitterUsers from './twitterusers-reducer'
import types from './types-reducer'
import users from './users-reducer'
import vehicles from './vehicles-reducer'
import wordpress from './wordpress-reducer'

const rootReducer = combineReducers({
  adminLogs,
  auth,
  authLogs,
  branch,
  chats,
  checklistResources,
  checklistTypes,
  checklists,
  classifications,
  companies,
  contactGroups,
  contacts,
  customMetadata,
  devices,
  documents,
  editorials,
  facebook,
  facebookCategories,
  facebookPages,
  feedLanes,
  google,
  groups,
  gpt,
  guidelines,
  iitemplates,
  index,
  institutions,
  ldap,
  license,
  locks,
  mail,
  medias,
  mosplugin,
  news,
  nominatim,
  posts,
  programs,
  reports,
  reportCategories,
  reportEvents,
  reportages,
  reuters,
  rounds,
  rssCategories,
  rssFeeds,
  rundownTemplates,
  rundowns,
  segments,
  souv,
  stories,
  storageFile,
  streaming,
  twitter,
  twitterCategories,
  twitterLists,
  twitterUsers,
  types,
  users,
  vehicles,
  wordpress,
})

export type RootState = ReturnType<typeof rootReducer>

export type PropsMapper<
  S extends (state: RootState, ownProps: T) => unknown, // mapped state types
  D = unknown, // dispatch actions types
  T = unknown // aditional props types
> = ReturnType<S> & D & T

export default rootReducer
